export const reviews = {
  isBorderedSnapshots: true,
  showMostLikedSection: false,
  showSnapshotIcon: false,
  showSizeAndWidthChart: true,
  isColorVariantId: true,
  isScrollToReviewsWhenPaging: true,
  isTrimProductIdAmount: false,
  showSubmittedByBeforeDesc: true,
  useWriteAReviewButton: true,
  showPaginationSeperator: true,
  showPaginationIcons: true,
  paginationIconPrevious: 'arrow_left',
  paginationIconNext: 'arrow_right',
  showStarsIndicator: false,
  sameLineLabels: true,
  useExpandedReviewsPanel: true,
  snapshotOrderedList: ['recommendations', 'reviews'],
};
