




































import { focus } from '@vf/shared/src/utils/directives';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
  name: 'VfCollapsible',
  directives: { focus },
  props: {
    header: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: 'span',
    },
    iconSize: {
      type: String,
      default: '14px',
    },
    isOpen: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const open = ref(props.isOpen);

    watch(
      () => props.isOpen,
      (value) => {
        open.value = value;
      }
    );

    const direction = computed(() => (open.value ? 'down' : 'up'));
    const toggle = () => {
      open.value = !open.value;
      emit('toggle', open.value);
    };

    const ariaLabelledByKey = computed(
      () =>
        'tab-panel-name-' +
        (props.header?.replace(/\s+/g, '-').toLowerCase() ?? 'no-header')
    );
    const idContent = computed(() => `${ariaLabelledByKey.value}-content`);

    return {
      open,
      direction,
      toggle,
      ariaLabelledByKey,
      idContent,
    };
  },
});
