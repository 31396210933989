export const giftOption = {
  giftBoxImage:
    'https://images.thenorthface.com/is/image/TheNorthFace/Gift%20Box%20with%20GC',
  giftBoxImageWidth: 326,
  giftBoxImageHeight: 240,
  itemImageWidth: 66,
  itemImageHeight: 78,
  editableItemImageWidth: 110,
  editableItemImageHeight: 129,
  maxMessageCharacters: 200,
};
