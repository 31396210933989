





























































































































































































































import {
  computed,
  defineComponent,
  inject,
  nextTick,
  PropType,
  reactive,
  ref,
  watch,
} from '@vue/composition-api';
import { mask } from 'ke-the-mask';
import { validationMixin } from 'vuelidate';
import {
  BasicInformationObject,
  BasicInformationTranslations,
  FormValidationConfig,
  BasicInformationValidationMessages,
} from '@vf/api-contract';
import { AddressCustomer } from '@vf/api-client';
import {
  getDateMaskFor,
  getDatePlaceholderFor,
  translateFromApiDateFormat,
  translateToApiDateFormat,
} from '@vf/shared/src/utils/form-helpers';
import { LoyaltyInterestsItemProp } from '../types';
import { helpers, requiredIf } from 'vuelidate/lib/validators';
import {
  checkAge,
  checkDate,
  checkPhone,
  checkName,
} from '@vf/shared/src/utils/helpers';
import { PhoneInputCountry } from '@vf/composables/src/useUtilities';
import { CountryCode } from 'libphonenumber-js';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

type ProfileData = {
  email: string;
  firstName?: string;
  lastName?: string;
  birthDate?: string;
  phone?: string;
  interests?: LoyaltyInterestsItemProp[];
  postalCode?: string;
  address: {
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    province?: string;
    postalCode?: string;
  };
  preferences?: {
    preferredShoeSize?: string;
    preferredShoeSizeGender?: string;
    interests?: string;
  };
};

export default defineComponent({
  name: 'BasicInformation',
  directives: { mask },
  mixins: [validationMixin],
  props: {
    translations: {
      type: Object as PropType<BasicInformationTranslations>,
      required: true,
    },
    basicInformation: {
      type: Object as PropType<BasicInformationObject>,
      default: () => ({}),
    },
    validationMessages: {
      type: Object as PropType<BasicInformationValidationMessages>,
      default: () => ({}),
    },
    validationConfig: {
      type: Object as PropType<FormValidationConfig>,
      default: () => ({}),
    },
    loyaltyAddress: {
      type: Object as PropType<AddressCustomer>,
      default: null,
    },
    provinces: {
      type: Array,
      default: () => [],
    },
    phoneInputCountries: {
      type: Array as PropType<PhoneInputCountry[]>,
      default: () => [],
    },
    phoneInputDefaultCountryCode: {
      type: String as PropType<CountryCode>,
      default: 'US',
    },
    showPhoneInputCountry: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      default: 'en-US',
    },
    interests: {
      type: Array as PropType<LoyaltyInterestsItemProp[]>,
      default: () => [],
    },
    minRegisterAge: {
      type: Number,
      required: true,
    },
    isLoyaltyEnrolled: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['save'],
  setup(props) {
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    const { isSimplifiedEnrollmentEnabled } = useFeatureFlagsStore();

    const state = reactive<ProfileData>({
      email: '',
      firstName: '',
      lastName: '',
      birthDate: '',
      phone: '',
      interests: [] as LoyaltyInterestsItemProp[],
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        province: '',
        postalCode: '',
      },
    });

    const selectedShoeSize = reactive({
      size: '',
      gender: '',
    });

    watch(
      () => props.basicInformation,
      (data) => {
        if (!data?.preferences) return;

        state.email = data.email;
        state.firstName = data.firstName;
        state.lastName = data.lastName;
        state.phone = data.phone || '';
        state.birthDate = translateFromApiDateFormat(
          data.birthDate,
          props.locale
        );

        selectedShoeSize.gender = data.preferences.preferredShoeSizeGender;
        selectedShoeSize.size = data.preferences.preferredShoeSize;

        const selectedInterests = data.preferences?.interests?.split('|');
        state.interests = props.interests?.map((interest) => ({
          ...interest,
          isActive: selectedInterests?.includes(interest.code),
        }));

        state.address.postalCode = props.loyaltyAddress?.postalCode
          ? props.loyaltyAddress.postalCode
          : data.postalCode;
      },
      { immediate: true }
    );

    watch(
      () => [props.provinces, props.loyaltyAddress],
      async () => {
        if (props.loyaltyAddress) {
          state.address.addressLine1 = props.loyaltyAddress.addressLine1;
          state.address.addressLine2 = props.loyaltyAddress.addressLine2;
          state.address.city = props.loyaltyAddress.city;
          if (props.loyaltyAddress.postalCode)
            state.address.postalCode = props.loyaltyAddress.postalCode;

          if (props.provinces?.length > 0) {
            await nextTick();
            state.address.province = props.loyaltyAddress.province;
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    const postalCodeMask = ref('#####');
    const datePlaceholder = computed(() => getDatePlaceholderFor(props.locale));
    const dateMask = computed(() => getDateMaskFor(props.locale));

    const birthdayRegex = computed(
      () => props.validationConfig?.birthdayRegex || `[\\d*]`
    );

    const phoneNumberMask = computed(
      () => props.validationConfig?.phoneNumberMask
    );

    const provinceChange = (value) => {
      if (value === props.translations.provinceLabel) {
        state.address.province = '';
      } else {
        state.address.province = value;
      }
    };

    const handleInterestsChange = ({
      code,
      isActive,
    }: LoyaltyInterestsItemProp) => {
      const interest = state.interests.find(
        (interest) => interest.code === code
      );
      interest.isActive = isActive;
    };

    const getDisabledState = (fieldName: string) => {
      return (
        props.disabledFields.some((field: string) => field === fieldName) &&
        Boolean(props.basicInformation[fieldName])
      );
    };

    const getRequiredState = (fieldName: string) => {
      if (!isSimplifiedEnrollmentEnabled) {
        return true;
      }

      return !!props.basicInformation[fieldName];
    };

    const isAddressRequired = computed(() => {
      if (!isSimplifiedEnrollmentEnabled) {
        return true;
      }

      return !!(
        state.address.addressLine1 ||
        props.loyaltyAddress?.addressLine1 ||
        (state.address.province &&
          state.address.province !== props.translations.provinceLabel) ||
        props.loyaltyAddress?.province ||
        state.address.city ||
        props.loyaltyAddress?.city
      );
    });

    return {
      getDisabledState,
      getRequiredState,
      isAddressRequired,
      state,
      selectedShoeSize,
      datePlaceholder,
      dateMask,
      birthdayRegex,
      postalCodeMask,
      phoneNumberMask,
      getPhoneMessage: (validationObj) => {
        if (!validationObj.$error) return;
        if (!validationObj.checkPhone) {
          return props.validationMessages.phoneError.replace(
            '{{phoneNumberFormat}}',
            props.validationConfig.phoneNumberFormat
          );
        }
        return props.validationMessages.requiredError;
      },
      getPostalCodeMessage: (validationObj) => {
        if (!validationObj.$error) return;
        if (!validationObj.valid) {
          return props.validationMessages.postalCodeError
            .replace('{{zipCodeLabel}}', '')
            .replace('{{zipCodeFormat}}', props.validationConfig.zipCodeFormat);
        }
        return props.validationMessages.requiredError;
      },
      getBirthdateMessage: (validationObj) => {
        if (!validationObj.$error) return;
        if (!validationObj.valid) {
          return props.validationMessages.birthdayError.replace(
            '{{dateFormat}}',
            datePlaceholder.value
          );
        }
        if (!validationObj.minAge) {
          return props.validationMessages.minRegisterAgeError.replace(
            '{{minRegisterAge}}',
            String(props.minRegisterAge)
          );
        }
        return props.validationMessages.requiredError;
      },
      provinceChange,
      handleInterestsChange,
      isCoreRedesignEnabled,
      isSimplifiedEnrollmentEnabled,
    };
  },
  computed: {
    countryCode() {
      return this.locale.split('-')[1];
    },
  },
  methods: {
    handleSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const birthDate = translateToApiDateFormat(
        this.state.birthDate,
        this.locale
      );

      const profileData: ProfileData = {
        email: this.state.email,
        address: this.state.address,
      };

      for (const key in this.state) {
        if (this.state[key]) {
          profileData[key] = this.state[key];
        }
      }

      profileData.postalCode = this.state.address.postalCode;
      if (birthDate) profileData.birthDate = birthDate;

      const savedPreferences = this.basicInformation.preferences || {};

      profileData.preferences = {
        ...savedPreferences,
        preferredShoeSize:
          this.selectedShoeSize.size || savedPreferences.preferredShoeSize,
        preferredShoeSizeGender:
          this.selectedShoeSize.gender ||
          savedPreferences.preferredShoeSizeGender,
        interests: this.state.interests
          .filter((interest) => interest.isActive)
          .map((interest) => interest.code)
          .join('|'),
      };

      this.disabledFields.forEach((fieldName: string) => {
        if (this.getDisabledState(fieldName)) {
          delete profileData[fieldName];
        }
      });

      this.$emit('save', profileData, this.isAddressRequired);
    },
  },
  validations() {
    return {
      state: {
        firstName: {
          required: requiredIf(function () {
            if (!this.isSimplifiedEnrollmentEnabled) {
              return true;
            }

            return this.basicInformation.firstName;
          }),
          checkName: (name) => {
            const check = checkName(
              this.$themeConfig?.basicInformation?.nameRegExp
            );

            if (this.isSimplifiedEnrollmentEnabled) {
              return name ? check(name) : true;
            }

            return check(name);
          },
        },
        lastName: {
          required: requiredIf(function () {
            if (!this.isSimplifiedEnrollmentEnabled) {
              return true;
            }

            return this.basicInformation.lastName || this.isAddressRequired;
          }),
          checkName: (name) => {
            const check = checkName(
              this.$themeConfig?.basicInformation?.nameRegExp
            );

            if (this.isSimplifiedEnrollmentEnabled) {
              return name ? check(name) : true;
            }

            return check(name);
          },
        },
        phone: {
          required: requiredIf(function () {
            if (!this.isSimplifiedEnrollmentEnabled) {
              return this.isLoyaltyEnrolled;
            }

            return this.isLoyaltyEnrolled && this.basicInformation.phone;
          }),
          checkPhone: () => {
            let shouldCheckPhone = this.isLoyaltyEnrolled;

            if (this.isSimplifiedEnrollmentEnabled) {
              shouldCheckPhone = shouldCheckPhone && this.state.phone;
            }

            return shouldCheckPhone
              ? checkPhone(this.state.phone, this.countryCode)
              : true;
          },
        },
        birthDate: {
          required: requiredIf(function () {
            if (!this.isSimplifiedEnrollmentEnabled) {
              return this.isLoyaltyEnrolled;
            }

            const birthDate = translateFromApiDateFormat(
              this.basicInformation.birthDate,
              this.locale
            );

            return this.isLoyaltyEnrolled && birthDate;
          }),
          valid: checkDate(this.locale),
          minAge: checkAge(this.minRegisterAge, this.locale),
        },
        address: {
          addressLine1: {
            required: requiredIf(function () {
              if (!this.isSimplifiedEnrollmentEnabled) {
                return this.isLoyaltyEnrolled;
              }

              return (
                this.isLoyaltyEnrolled &&
                (this.loyaltyAddress.adressLine1 || this.isAddressRequired)
              );
            }),
          },
          city: {
            required: requiredIf(function () {
              if (!this.isSimplifiedEnrollmentEnabled) {
                return this.isLoyaltyEnrolled;
              }

              return (
                this.isLoyaltyEnrolled &&
                (this.loyaltyAddress.city || this.isAddressRequired)
              );
            }),
          },
          province: {
            required: requiredIf(function () {
              if (!this.isSimplifiedEnrollmentEnabled) {
                return this.isLoyaltyEnrolled;
              }

              return (
                this.isLoyaltyEnrolled &&
                (this.loyaltyAddress.province || this.isAddressRequired)
              );
            }),
          },
          postalCode: {
            required: requiredIf(function () {
              if (!this.isSimplifiedEnrollmentEnabled) {
                return this.isLoyaltyEnrolled;
              }

              const postalCode = this.loyaltyAddress?.postalCode
                ? this.loyaltyAddress.postalCode
                : this.basicInformation.postalCode;

              return (
                this.isLoyaltyEnrolled && (postalCode || this.isAddressRequired)
              );
            }),
            valid: helpers.regex(
              'valid',
              new RegExp(this.validationConfig.zipCodeRegex)
            ),
          },
        },
      },
    };
  },
});
